import React, { Component } from "react"
import { Link } from "gatsby"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from "@chakra-ui/core"
const WhiteButton = ({ children, onClick }) => (
  <Button
    variantColor="white"
    variant="outline"
    rightIcon="chevron-down"
    onClick={onClick}
  >
    {children}
  </Button>
)
class MenuLinks extends Component {
  render() {
    // const { isActive } = this.props
    return (
      <>
        <Box display={["none", "block"]}>
          <Breadcrumb separator="•">
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/">
                Marie Tae McDermott
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/work">
                Select Works
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/contact">
                Contact
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>
        <Box display={["block", "none"]} mb="5">
          <Menu>
            <MenuButton as={WhiteButton} rightIcon="chevron-down">
              Marie Tae McDermott
            </MenuButton>
            <MenuList>
              <MenuItem as={Link} to="/">
                Home
              </MenuItem>
              <MenuItem as={Link} to="/work">
                Select Works
              </MenuItem>
              <MenuItem as={Link} to="/contact">
                Contact
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </>
    )
  }
}

export default MenuLinks
