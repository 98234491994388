import React from "react"
import MenuLinks from "./MenuLinks"

import { rhythm, scale } from "../utils/typography"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    // const rootPath = `${__PATH_PREFIX__}/`

    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          // maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>
          <MenuLinks />
        </header>
        <main>{children}</main>
        {/* <footer>© {new Date().getFullYear()}, Marie Tae McDermott</footer> */}
      </div>
    )
  }
}

export default Layout
