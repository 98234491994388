/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { Text, Link, Box, Flex } from "@chakra-ui/core"
import { rhythm } from "../utils/typography"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/marie-tae-mcdermott.jpg/" }) {
        childImageSharp {
          fixed(width: 150, height: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <Box p={4} display={{ md: "flex" }}>
      <Box flexShrink="0">
        <Image
          fixed={data.avatar.childImageSharp.fixed}
          alt={author}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            minWidth: 50,
            borderRadius: `100%`,
          }}
          imgStyle={{
            borderRadius: `50%`,
          }}
        />
      </Box>
      <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }} display={{ md: "flex" }}>
        <Flex align="center" justify="center">
          <Text fontSize="md">
            Twitter:
            <Link href={`https://twitter.com/${social.twitter}`} isExternal>
              @marzietae
            </Link>{" "}
            |{` `}
            Email:{" "}
            <Link href="mailto:marie.mcdermott@nytimes.com" isExternal>
              marie.mcdermott@nytimes.com
            </Link>
          </Text>
        </Flex>
      </Box>
    </Box>
  )
}

export default Bio
